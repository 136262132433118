import React, {useEffect, useState} from 'react';
import './style.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose} from '@fortawesome/pro-light-svg-icons';
import Logo from "../../assets/images/gt-icon.png";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import branch from "branch-sdk";

const DownloadApp = ({setShowDownloadSection}) => {
    const {t} = useTranslation();
    const [downloadLink, setLink] = useState('');

    useEffect(() => {
        createLink();
    }, []);

    const createLink = () => {
        try {
            let linkData = {
                campaign: 'genius/download_app',
                data: {
                    payload: null,
                    '$og_title': 'Genius App',
                    '$og_description': t("DownloadSectionDescription"),
                    '$og_image_url': 'https://genius.travel/static/media/mobile-App-Image.6d20f73fbdead69c5ed8.png'
                }
            };

            branch.link(linkData, function (err, link) {
                setLink(link)
            });
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className={'container-download container'}>
            <FontAwesomeIcon className="icon" icon={faClose} onClick={() => setShowDownloadSection(false)}/>
            <img src={Logo} alt="app-logo" className="brand_logo"/>
            <div className="app_info">
                <h6>Genius App</h6>
                <p>{t("DownloadSectionDescription")}</p>
            </div>
            <a href={downloadLink}>Download</a>
        </div>
    );
};

DownloadApp.propTypes = {
    setShowDownloadSection: PropTypes.func
}

export default DownloadApp;