import React from "react";
import TopImage from "../../../assets/images/genius_bestemming.svg";
import FloatingImage from "../../../assets/images/top-section-floating-image.svg";
import AeroplaneBed from "../../../assets/images/icons/aroplane-bed.svg";
import QuickFilter from "../QuickFilters/index";
import "./style.scss";
import {useTranslation} from "react-i18next";

const TopSection = () => {
    const {t} = useTranslation();

    return (
        <section id="top-section" className="top-section-wrapper">
            <div className="container">
                <div className="content-wrapper d-flex">
                    <div className="image-holder">
                        <img src={TopImage} alt={'top-img'}/>
                    </div>
                    <img src={FloatingImage} className="floating-image" alt={'floating-image'}/>
                    <div className="content-hodler">
                        <h6 className="subtitle heading fs-17 white-text font-noto">
                            <img src={AeroplaneBed} alt={'aeroplane'}/>
                            {t("HotelInclFlight").toUpperCase()}
                        </h6>
                        <h1 className="heaidng fs-68 white-text bold font-noto">
                            {t("ExploreGlobe")}
                        </h1>
                        <p className="white-text heading-text fs-22 font-noto ">
                            {t("FindExperience")}
                        </p>
                    </div>
                </div>
                <div className="home-quick">
                    <QuickFilter/>
                </div>
            </div>
        </section>
    );
};

export default TopSection;
