// import {t} from "i18next";
import React from "react";
import "./style.scss";
import FlightCardModal from "../../Modals/FlightCardModal";

// images
import PlaneIcon from "../../../assets/images/icons/plane-solid.svg";
import PlaneAltIcon from "../../../assets/images/icons/plane-return.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSeatAirline, faTicketAirline} from "@fortawesome/pro-solid-svg-icons";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import {faPlaneUp} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import moment from "moment/moment";
import {getCurrencyBadge} from "../../../helpers/common";
import {useTranslation} from "react-i18next";

const FlightCard = ({
                        flight,
                        index,
                        tempPrice = null,
                        currentSlideIndex = null,
                        departure,
                        arrival,
                        setShouldStop = null
                    }) => {
    const {t} = useTranslation();
    const [modalShow, setModalShow] = React.useState(false);
    const getEstimateTimeFormat = (estimateTime) => {
        let text = (f, s) =>
            estimateTime?.substring(
                estimateTime.indexOf(f) + 1,
                estimateTime?.lastIndexOf(s)
            );
        return `${text("T", "H")}h ${text("H", "M")}m`;
    };

    const getTotalStops = (stops) => {
        if (stops === "0") {
            return "Non Stop";
        } else if (stops === "1") {
            return `${stops} stop`;
        } else {
            return `${stops} stops`;
        }
    };

    const getSurchargesString = (surcharges) => {
        return surcharges?.includes("-") ? `${surcharges}.00` : `+${surcharges}.00`;
    };

    const tempPriceStyle = {
        color:
            tempPrice && Number(tempPrice) < 0 && currentSlideIndex === index
                ? "#05CE78"
                : "#1F6DE7",
    }

    return (
        <div>
            <div className="flight-card-holder">
                <div className="box-flight-wrapper">
                    <div className="flight-box-detail one-way">
                        <div className="icons-left">
                            <img src={PlaneAltIcon} alt="plane"/>
                            <div className="line"></div>
                            <img style={{transform: "rotate(317deg)"}} src={PlaneIcon} alt="plane"/>
                        </div>
                        <div className="flight-details">
                            <div className="departure-details">
                                <div>
                                    <h5>{departure?.departureAirport?.code}</h5>
                                    <p>{departure?.departureAirport?.name}</p>
                                </div>
                                <img src={departure?.icon} alt=""/>
                                <div className="text-right">
                                    <h5>{moment
                                        .parseZone(departure?.departureDateTime)
                                        .format("HH:mm")}</h5>
                                    <p> {moment
                                        .parseZone(departure?.departureDateTime)
                                        .format("ddd, MMM DD")}</p>
                                </div>
                            </div>
                            <div className="flight-mid-details">
                                <div>
                                    <div className="icon-text">
                                        <FontAwesomeIcon icon={faSeatAirline}/>
                                        <span>{departure?.cabinClass}</span>
                                    </div>
                                    <div className="icon-text">
                                        <FontAwesomeIcon icon={faClock}/>
                                        <span>{`${getTotalStops(departure?.numberOfStops)} | ${getEstimateTimeFormat(departure?.estimateTime)}`}</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="icon-text">
                                        <FontAwesomeIcon icon={faTicketAirline}/>
                                        <span>{departure?.departureStart?.flightDuration}</span>
                                    </div>
                                    <div className="icon-text">
                                        <FontAwesomeIcon icon={faPlaneUp}/>
                                        <span>{departure?.departureStart?.equipmentName || ''}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="arrival-details">
                                <div>
                                    <h5>{departure?.arrivalAirport?.code}</h5>
                                    <p>{departure?.arrivalAirport?.name}</p>
                                </div>
                                <img src="" alt=""/>
                                <div className="text-right">
                                    <h5>{moment
                                        .parseZone(departure?.arrivalDateTime)
                                        .format("HH:mm")}</h5>
                                    <p>{moment
                                        .parseZone(departure?.arrivalDateTime)
                                        .format("ddd, MMM DD")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flight-box-detail return-flight">
                        <div className="icons-left">
                            <img src={PlaneIcon} alt="plane"/>
                            <div className="line"></div>
                            <img style={{transform: "rotate(45deg)"}} src={PlaneAltIcon} alt="plane"/>
                        </div>
                        <div className="flight-details">
                            <div className="departure-details">
                                <div>
                                    <h5>{arrival?.departureAirport?.code}</h5>
                                    <p>{arrival?.departureAirport?.name}</p>
                                </div>
                                <img src={arrival?.icon} alt=""/>
                                <div className="text-right">
                                    <h5>{moment
                                        .parseZone(arrival?.departureDateTime)
                                        .format("HH:mm")}</h5>
                                    <p> {moment
                                        .parseZone(arrival?.departureDateTime)
                                        .format("ddd, MMM DD")}</p>
                                </div>
                            </div>
                            <div className="flight-mid-details">
                                <div>
                                    <div className="icon-text">
                                        <FontAwesomeIcon icon={faSeatAirline}/>
                                        <span>{arrival?.cabinClass}</span>
                                    </div>
                                    <div className="icon-text">
                                        <FontAwesomeIcon icon={faClock}/>
                                        <span>{`${getTotalStops(arrival?.numberOfStops)} | ${getEstimateTimeFormat(arrival?.estimateTime)}`}</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="icon-text">
                                        <FontAwesomeIcon icon={faTicketAirline}/>
                                        <span>{arrival?.arrivalStart?.flightDuration}</span>
                                    </div>
                                    <div className="icon-text">
                                        <FontAwesomeIcon icon={faPlaneUp}/>
                                        <span>{arrival?.arrivalStart?.equipmentName}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="arrival-details">
                                <div>
                                    <h5>{arrival?.arrivalAirport?.code}</h5>
                                    <p>{arrival?.arrivalAirport?.name}</p>
                                </div>
                                <img src="" alt=""/>
                                <div className="text-right">
                                    <h5>{moment
                                        .parseZone(arrival?.arrivalDateTime)
                                        .format("HH:mm")}</h5>
                                    <p>{moment
                                        .parseZone(arrival?.arrivalDateTime)
                                        .format("ddd, MMM DD")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="seprator-mask"></div>
                    <div className="pricing-box">
                        <div className="price">
                            <p className="addon" style={tempPriceStyle}>
                                {`${getCurrencyBadge(
                                    flight?.price?.averageCostPerPerson?.currency
                                )} ${
                                    flight?.surcharges
                                        ? getSurchargesString(
                                            tempPrice &&
                                            Number(tempPrice) !== 0 &&
                                            currentSlideIndex === index
                                                ? tempPrice
                                                : flight?.surcharges,
                                        )
                                        : "+0.00"
                                }`}<span> p.p</span>
                            </p>
                        </div>
                        <a className="action-btn" onClick={() => {
                            setModalShow(true);
                            setShouldStop(true);
                        }}>{t('Buttons.View')}</a>
                    </div>
                </div>
            </div>
            <FlightCardModal show={modalShow}
                             onHide={() => {
                                 setModalShow(false);
                                 setShouldStop(false);
                             }} flight={flight}
            />
        </div>
    );
};

FlightCard.propTypes = {
    flight: PropTypes.object,
    index: PropTypes.number,
    tempPrice: PropTypes.any,
    currentSlideIndex: PropTypes.number,
    departure: PropTypes.object,
    arrival: PropTypes.object,
    selectHandler: PropTypes.func,
    setShouldStop: PropTypes.func
};

export default FlightCard;
