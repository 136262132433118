import React, {useCallback} from 'react';

import {useGoogleLogin} from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import AppleSignin from 'react-apple-signin-auth';

import GoogleIcon from "../../assets/images/google.svg";
import FacebookIcon from "../../assets/images/facebook.svg";
import {setActivityIndicator, setModals} from "../../redux/slices/appconfigSlice";
import {PostValidateAuth} from "../../services/LoginService";
import {setIsLogin, setLoginUser} from "../../redux/slices/loginconfigSlice";
import {postWithoutBody} from "../../services/HttpClient";
import AppConfig from "../../helpers/config";
import AppleIcon from "../../assets/images/apple.svg";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

const SocialLogins = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const handleLoginResponse = useCallback(
        async (userObj, provider) => {
            if (userObj?.guidOfUser) {
                dispatch(setActivityIndicator(true));
                const res = await postWithoutBody(
                    `${AppConfig.baseApiURL}/Authenticate/GetAccessToken`,
                );
                await localStorage.setItem("auth_token", res.data.access_token);
                dispatch(setActivityIndicator(false));

                const nameParts = userObj?.userName?.split(' ');
                const loginObject = {
                    email: userObj?.userMail || '',
                    firstname: nameParts?.shift() || '',
                    guid: userObj?.guidOfUser || '',
                    lastname: nameParts?.join(' ') || '',
                    provider,
                };

                dispatch(setLoginUser(loginObject));
                dispatch(setIsLogin(true));
                dispatch(setModals({login: false, register: false, confirmation: false}));
            }
        },
        [dispatch],
    );

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: async tokenResponse => {
            try {
                console.log({tokenResponse});
                dispatch(setActivityIndicator(true));
                const userObj = await PostValidateAuth({
                    provider: 'google',
                    token: tokenResponse.access_token,
                });
                dispatch(setActivityIndicator(false));
                console.log({userObj});
                await handleLoginResponse(userObj, 'google');
            } catch (e) {
                console.log({e}, 'google sign error');
                dispatch(setActivityIndicator(false));
            }
        },
    });

    const responseFacebook = async (response) => {
        try {
            console.log({response});
            if (response?.accessToken) {
                dispatch(setActivityIndicator(true));
                const userObj = await PostValidateAuth({
                    provider: 'facebook',
                    token: response?.accessToken,
                });
                dispatch(setActivityIndicator(false));
                console.log({userObj});
                await handleLoginResponse(userObj, 'facebook');
            }
        } catch (e) {
            console.log({e}, 'facebook sign error');
            dispatch(setActivityIndicator(false));
        }
    }

    const appleOnSuccess = async (response) => {
        const {authorization} = response;
        if (authorization?.id_token) {
            try {
                dispatch(setActivityIndicator(true));
                const userObj = await PostValidateAuth({
                    provider: 'apple',
                    token: authorization?.id_token,
                });
                console.log({userObj})
                dispatch(setActivityIndicator(false));
                await handleLoginResponse(userObj, 'apple');
            } catch (e) {
                dispatch(setActivityIndicator(false));
                console.log({e}, 'verifying apple token failed.')
            }
        }
    }
    // Todo
    const AppleSignInBtn = ({...rest}) => (
        <AppleSignin
            authOptions={{
                clientId: 'travel.genius.applelogin',
                // redirectURI: 'https://geniustravel.demo3.appelit.com/auth/',
                // redirectURI: 'https://staging.genius.travel/auth/',
                redirectURI: 'https://genius.travel/auth/',
                scope: 'name email',
                responseMode: 'form_post', // Can be 'query' or 'fragment' as well
                responseType: 'code id_token', // Can be 'code' or 'id_token'
                state: 'state', // Optional, can be used to pass app-specific state
                nonce: 'nonce', // Optional, can be used to mitigate replay attacks
                usePopup: true, // Defaults to false
            }}
            onSuccess={appleOnSuccess}
            onError={(error) => console.log('AppleSignInBtn onError error', error)} // default = undefined
            render={(props) => (
                <a className="social-icons" {...props} rel="noopener noreferrer">
                    <img src={AppleIcon} alt="Apple Sign-In"/>
                </a>
            )}
            {...rest}
        />
    );

    return (
        <div className="mt-30">
            <div className="fs-12 font-noto">{t('SearchPage.Or')}</div>

            <a
                className='social-icons'
                rel='noopener noreferrer'
                onClick={handleGoogleLogin}
            >
                <img src={GoogleIcon} alt=""/>
            </a>

            <FacebookLogin
                appId="762941845725981"
                callback={responseFacebook}
                render={renderProps => (
                    <a
                        className="social-icons"
                        rel='noopener noreferrer'
                        onClick={renderProps.onClick}
                    >
                        <img src={FacebookIcon} alt=""/>
                    </a>
                )}
            />

            <AppleSignInBtn/>
        </div>
    );
};

export default SocialLogins;